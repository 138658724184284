require('./bootstrap');
import * as FilePond from 'filepond';

import FilePondPluginFileRename from 'filepond-plugin-file-rename';
FilePond.registerPlugin(FilePondPluginFileRename);

window.FilePond = FilePond;
try {
    window.$ = window.jQuery = require('jquery');
} catch (error) {
    console.log(error);
}
let admin_sidenav = {
    init: function () {
        this.cacheDom();
        this.bindEvents();
    },
    cacheDom: function () {
        this.$nav_container = $("#admin_sidebar_main");
        this.$nav_button_expandable = $(".nav-expandable");
        this.$admin_sidebar_nav_open = $("#admin_sidebar_nav_open");
        this.$admin_sidebar_nav_close = $("#admin_sidebar_nav_close");
        this.$admin_nav_toggle = $('.admin-nav-toggle');
    },
    bindEvents: function () {
        this.$admin_nav_toggle.on("click", this.toggleNav.bind(this));
        this.$nav_button_expandable.on("click", this.toggleMenuItem);
    },
    toggleNav: function () {
        console.log('toggleNav');
        this.$nav_container.toggleClass("-translate-x-full");
        this.$nav_container.toggleClass("translate-x-0");
        this.$admin_sidebar_nav_open.toggleClass("hidden");
        this.$admin_sidebar_nav_close.toggleClass("hidden");
    },
    toggleMenuItem: function () {
        let $this = $(this);
        console.log('toggleMenuItem');
        $this.find('i.fa-chevron-down').toggleClass("rotate-180");
        $this.next('.nav-sub').toggleClass("hidden");
    },
};
let modal_ui_triggers = {
    init: function () {
        this.bindEvents();
    },
    cacheDom: function () {
    },
    bindEvents: function () {
        $(document).on('click', '.open-modal', this.openModal);
        $(document).on('click', '.close-modal', this.closeModal);
    },
    openModal: function () {
        console.log('OPEN MODAL - ' + $(this).attr('data-target-modal'));
        $('#' + $(this).attr('data-target-modal')).removeClass('hidden');
    },
    closeModal: function () {
        $('#' + $(this).attr('data-target-modal')).addClass('hidden');
    },
};
let toggle_ui_triggers = {
    init: function () {
        this.cacheDom();
        this.bindEvents();
    },
    cacheDom: function () {
        this.$triggers = $(".toggle-trigger");
        this.$targets = $(".toggle-target");

    },
    bindEvents: function () {
        this.$triggers.on("click", this.toggleTarget);
        $(document).on('click', '.toggle-close', this.closeAll.bind(this));
    },
    toggleTarget: function () {
        let $target = $('.' + $(this).attr('data-target'));
        $target.toggleClass('invisible');
        $target.toggleClass('visible');
        setTimeout(function () {
            if ($target.hasClass('visible')) {
                $('body').addClass('toggle-close');
            } else {
                $('body').removeClass('toggle-close');
            }
        }, 50);
    },
    closeAll: function () {
        $('body').removeClass('toggle-close');
        setTimeout(function () {
            toggle_ui_triggers.$targets.addClass('invisible');
            toggle_ui_triggers.$targets.removeClass('visible');
        }, 100);
    }
};
$(document).ready(function () {
    //admin_sidenav.init();
    modal_ui_triggers.init();
    toggle_ui_triggers.init();
});


let top_nav_handler = {
    dropdownHandler: function (element) {
        let single = element.getElementsByTagName("ul")[0];
        single.classList.toggle("hidden");
    },
    MenuHandler: function (el, val) {
        let MainList = el.parentElement.parentElement.getElementsByTagName("ul")[0];
        let closeIcon = el.parentElement.parentElement.getElementsByClassName("close-m-menu")[0];
        let showIcon = el.parentElement.parentElement.getElementsByClassName("show-m-menu")[0];
        if (val) {
            MainList.classList.remove("hidden");
            el.classList.add("hidden");
            closeIcon.classList.remove("hidden");
        } else {
            showIcon.classList.remove("hidden");
            MainList.classList.add("hidden");
            el.classList.add("hidden");
        }
    },
};
window.top_nav_handler = top_nav_handler;



window.activeFolderID = null;
/*document.addEventListener('livewire:load', function () {
    activeFolderID = @this.activeFolderID;
})*/


$(document).ready(function () {
    //Load active folder from query string if present
    if (window.location.pathname === '/') {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        if (urlParams.has('view')) {
            window.activeFolderID = parseInt(urlParams.get('view'));
        }else{
            window.activeFolderID = null;
        }
    }
    Livewire.on('changeFolder', folderId => {
        if(typeof folderId === 'undefined') {
            folderId = null;
        }
        window.activeFolderID = folderId;
    });

    document.addEventListener('FilePond:addfilestart', (e) => {
        let fileTitles = jQuery('.filepond--file-info-main').not('.cleared-folder-id');
        fileTitles.each(function () {
            let $this = $(this);

            $this.html($this.html().split('_')[1]);
        });
    });
});
